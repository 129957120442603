// const config = useRuntimeConfig();
const apiUrl = import.meta.env.VITE_API_URL;

const endpoints = {
  userUrl: (id: string) => `${apiUrl}/user/${id}`,
  usersUrl: `${apiUrl}/user`,
  loginUrl: `${apiUrl}/login`,
  updateUrl: (userId: string) => `${apiUrl}/user/${userId}`,
  logoutUrl: `${apiUrl}/logout`,
  signupUrl: `${apiUrl}/signup`,
  resendConfirmEmailUrl: `${apiUrl}/resendConfirmEmail`,
  resetPasswordUrl: `${apiUrl}/resetPassword`,
  newPasswordUrl: `${apiUrl}/newPassword`,
};

export default endpoints;
