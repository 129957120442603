import endpoints from '@/endpoints/user';
import type {
  ReqPayloadLogin,
  ReqPayloadResetPassword,
  ReqSignupPayload,
  ResPayloadLogin,
} from '@/types/api/user';
// const config = useRuntimeConfig();
const {
  newPasswordUrl,
  resetPasswordUrl,
  userUrl,
  usersUrl,
  loginUrl,
  updateUrl,
  logoutUrl,
  signupUrl,
  resendConfirmEmailUrl,
} = endpoints;

const baseHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Allow-Origin': 'true',
};

export default {
  async getUser(userId: string) {
    return await fetch(userUrl(userId), {});
  },
  async getUsers() {
    return await fetch(usersUrl, {});
  },
  async login(payload: ReqPayloadLogin, options?: any): Promise<Response> {
    return await fetch(loginUrl, {
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  },
  async update(payload: any, options: any) {
    return await fetch(updateUrl(payload), {
      headers: baseHeaders,
      ...options,
      body: JSON.stringify(payload),
    });
  },
  async logout() {
    return await fetch(logoutUrl, {});
  },
  async signup(payload: ReqSignupPayload, options?: any) {
    return await fetch(signupUrl, {
      ...options,
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  },
  async resetPassword(payload: Pick<ReqSignupPayload, 'email'>, options?: any) {
    return await fetch(resetPasswordUrl, {
      ...options,
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  },
  async updatePassword(payload: any, options?: any) {
    return await fetch(newPasswordUrl, {
      ...options,
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  },
  async resendConfirmationEmail(payload: { email: string }, options?: any) {
    return await fetch(resendConfirmEmailUrl, {
      ...options,
      headers: baseHeaders,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  },
};
